<template>
  <div class="authenticated-layout-header">
    <OffCanvas
      v-model:is-open="showMenu"
      :items="contextMenuItems"
      :items-account="accountMenuItems"
    />
    <NotificationsSidebar v-model:show="showNotifications" />
    <AuthenticatedNavbar
      v-model:off-canvas-value="showMenu"
      v-model:notification-value="showNotifications"
    />
  </div>
</template>

<script setup lang="ts">
import { markRaw, onMounted, ref } from "vue";
import { useMarketplace } from "@/shared/composables/Marketplace";
import { useI18n } from "vue-i18n";

import { DsBadge, DsModalHandler } from "@devsalsa/vue-core";

import AuthenticatedNavbar from "@/core/shared/components/Navigation/AuthenticatedNavbar.vue";
import OffCanvas from "@/core/shared/components/Navigation/OffCanvas.vue";

import NotificationsSidebar from "@/modules/notification/components/NotificationsSidebar.vue";

import type { NavigationItem } from "@/core/shared/components/Navigation/Navigation.types";

import {
  PermissionType,
  ScopeType,
} from "@/modules/settings/enums/CollaboratorsEnum";

import CollaboratorHelper from "@/shared/helpers/CollaboratorHelper";

defineOptions({
  name: "AuthenticatedLayoutHeader",
});

const { t } = useI18n();
const { isTikTokAdCenterMarketplace } = useMarketplace();

const showMenu = ref<boolean>(false);
const showNotifications = ref<boolean>(false);
const contextMenuItems = ref<NavigationItem[]>([] as NavigationItem[]);
const accountMenuItems = ref<NavigationItem[]>([] as NavigationItem[]);

onMounted(() => {
  setContextMenu();
  setAccountMenuItems();
});

function setAccountMenuItems() {
  accountMenuItems.value.push({
    label: t("core.shared.components.layout.navbar.account"),
    route: {
      name: "Account",
    },
  });

  if (
    CollaboratorHelper.checkPermission(ScopeType.MySubscription, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.subscription"),
      route: {
        name: "Subscription",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Wallet, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.wallet"),
      route: {
        name: "Wallet",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Billing, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.billing"),
      route: {
        name: "Billing",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.PaymentMethods, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.paymentMethods"),
      route: {
        name: "PaymentMethods",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Brands, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.brands"),
      route: {
        name: "Brands",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Collaborators, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.collaborators"),
      route: {
        name: "Collaborators",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.WhiteLabeling, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.whiteLabeling"),
      route: {
        name: "WhiteLabeling",
      },
    });
  }

  accountMenuItems.value.push({
    label: t("core.shared.components.layout.navbar.notifications"),
    route: {
      name: "NotificationSettings",
    },
  });

  accountMenuItems.value.push({
    label: t("core.shared.components.layout.navbar.requestAFeature"),
    route: {
      name: "RequestAFeature",
    },
  });

  accountMenuItems.value.push({
    label: t("core.shared.components.layout.navbar.contact"),
    route: {},
    action: () => onOpenModal(),
  });

  if (
    CollaboratorHelper.checkPermission(ScopeType.AffiliateProgram, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    accountMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.affiliate"),
      route: {
        name: "AffiliateProgram",
      },
    });
  }
}

function setContextMenu() {
  contextMenuItems.value = [
    {
      label: t("core.shared.components.layout.navbar.dashboard"),
      route: {
        name: "Dashboard",
      },
    },
  ];
  if (
    CollaboratorHelper.checkPermission(ScopeType.Campaigns, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    contextMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.campaigns"),
      route: {
        name: "Campaigns",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.RecurringCampaigns, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    contextMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.recurringCampaigns"),
      route: {
        name: "RecurringCampaigns",
      },
      suffix: markRaw({
        name: DsBadge,
        attributes: {
          title: t("common.label.new"),
          themeColor: "pink",
          size: "xs",
          class: "px-1",
          dataTestid: "beta-badge",
        },
      }),
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Campaigns, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    contextMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.jobs"),
      route: {
        name: "Jobs",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.AdCenter, [
      PermissionType.Full,
      PermissionType.View,
    ]) &&
    isTikTokAdCenterMarketplace.value
  ) {
    contextMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.adCenter"),
      route: {
        name: "AdCenter",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Products, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    contextMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.products"),
      route: {
        name: "Products",
      },
    });
  }

  if (
    CollaboratorHelper.checkPermission(ScopeType.Content, [
      PermissionType.Full,
      PermissionType.View,
    ])
  ) {
    contextMenuItems.value.push({
      label: t("core.shared.components.layout.navbar.content"),
      route: {
        name: "Content",
      },
    });
  }
}

function onOpenModal() {
  DsModalHandler.open("contact-support-modal");
}
</script>
