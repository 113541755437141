import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { useMarketplace } from "@/shared/composables/Marketplace";

export default class OnboardingGuard {
  static async withIncompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();
    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    if (accountInfo?.onboard_position >= 1) {
      return next({
        name: "Dashboard",
      });
    }

    const sequence = [
      "CompanyInformation", //0
    ];
    const sequenceId = sequence.findIndex((step) => step === to.name);
    if (sequenceId > accountInfo?.onboard_position) {
      return next({
        name: sequence[accountInfo?.onboard_position],
      });
    }
    next();
  }

  /**
   * @param  {RouteLocationNormalized} to
   * @param  {RouteLocationNormalized} from
   * @param  {NavigationGuardNext} next
   * @returns void
   */
  static async withCompleteOnboarding(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ) {
    if (!AccountHandler.isAuthenticated()) {
      return next({
        name: "Login",
      });
    }

    const accountInfo = await AccountHandler.getInfo();

    if (!accountInfo) {
      return next({
        name: "Login",
      });
    }

    const conditionEmailVerified =
      accountInfo?.email != null && accountInfo?.email_verified;
    if (!conditionEmailVerified) {
      return next({
        name: "ConfirmEmail",
      });
    }

    const sequence = [
      "CompanyInformation", //0
    ];
    if (
      sequence.some(
        (step: string, index: number) => accountInfo?.onboard_position === index
      )
    ) {
      return next({
        name: sequence[accountInfo?.onboard_position],
      });
    }

    if (
      (from.name === "CompanyInformation" || from.name === "Login") &&
      useMarketplace().currentMarketplace() !==
        accountInfo.default_marketplace.short_code
    ) {
      window.location.href = `https://${
        accountInfo.default_marketplace.short_code
      }.${import.meta.env.VITE_APP_DOMAIN}`;
    }

    next();
  }
}
