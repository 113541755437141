import type { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import CompanyService from "@/shared/services/CompanyService";
import AssetsService from "@/shared/services/AssetsService";
import { useMarketplace } from "@/shared/composables/Marketplace";

export default class TikTokAdCenterGuard {
  static async withStats(to: RouteLocationNormalized) {
    to.meta.companyStats = await CompanyService.stats();
    to.meta.tiktokAdEnums = await AssetsService.tiktokAdEnums();
    to.meta.brands = await CompanyService.getBrands();
  }

  static async withMarketplaceAvailable(
    to: RouteLocationNormalized,
    from: RouteLocationNormalized,
    next: NavigationGuardNext
  ): Promise<void> {
    if (!useMarketplace().isTikTokAdCenterMarketplace.value) {
      return next({
        name: "Dashboard",
      });
    }

    next();
  }
}
