import type { RouteLocationNormalized } from "vue-router";
import AccountGuard from "@/core/modules/account/helpers/AccountGuard";
import { ScopeType } from "@/modules/settings/enums/CollaboratorsEnum";
import OnboardingGuard from "@/modules/onboarding/helpers/OnboardingGuard";
import TikTokAdCenterGuard from "@/modules/tiktok-ad-center/helpers/TikTokAdCenterGuard";

export default [
  {
    path: "",
    beforeEnter: [
      OnboardingGuard.withCompleteOnboarding,
      AccountGuard.withPermissions,
      TikTokAdCenterGuard.withStats,
      TikTokAdCenterGuard.withMarketplaceAvailable,
    ],
    children: [
      {
        path: "/ad-center",
        name: "AdCenter",
        component: () =>
          import("@/modules/tiktok-ad-center/views/TiktokAdCenter.vue"),
        meta: {
          title: "modules.adCenter.meta",
          scope: ScopeType.AdCenter,
        },
      },
      {
        path: "open-tiktok-url",
        name: "OpenTiktokUrl",
        meta: {
          title: "modules.adCenter.meta",
        },
        redirect: (to: RouteLocationNormalized) => {
          const url = to.query.tiktokUrl as string;
          window.open(url, "_blank");
          return { name: "AdCenter" };
        },
      },
    ],
  },
];
