<template>
  <table class="table-auto w-full border-y border-gray-100 mb-6 text-sm">
    <tbody class="divide-y divide-gray-100" data-testid="wire-money-info-table">
      <!-- Bank account name -->
      <tr data-testid="bank-account-name">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              $t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.ca.label.bankAccountName"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="bank-account-name">
              {{
                $t(
                  "modules.settings.wallet.wireMoneyModal.marketplaces.ca.info.bankAccountName"
                )
              }}
            </p>
            <DsButton
              @click="copy('bank-account-name')"
              theme-display="link"
              class="inline-flex"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!-- Account number -->
      <tr data-testid="account-number">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              $t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.au.label.accountNumber"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="account-number">
              {{
                $t(
                  "modules.settings.wallet.wireMoneyModal.marketplaces.au.info.accountNumber"
                )
              }}
            </p>
            <DsButton
              @click="copy('account-number')"
              theme-display="link"
              class="inline-flex"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!-- BSB code -->
      <tr data-testid="wire-BSB">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              $t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.au.label.bsbCode"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="bsb">
              {{
                $t(
                  "modules.settings.wallet.wireMoneyModal.marketplaces.au.info.bsbCode"
                )
              }}
            </p>
            <DsButton
              @click="copy('bsb')"
              theme-display="link"
              class="inline-flex"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>

      <!--Reference -->
      <tr data-testid="wire-reference">
        <td class="py-3 w-40 max-w-40">
          <div class="text-gray-500">
            {{
              $t(
                "modules.settings.wallet.wireMoneyModal.marketplaces.au.label.reference"
              )
            }}
          </div>
        </td>
        <td class="py-3 text-gray-900">
          <div class="flex justify-between items-center">
            <p id="reference" class="font-semibold">
              {{ reference }}
            </p>
            <DsButton
              @click="copy('reference')"
              theme-display="link"
              class="inline-flex"
            >
              <DsCopyOutlineIcon
                class="h-5 w-5 block text-gray-900 ml-2 fill-transparent"
              />
            </DsButton>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script lang="ts" setup>
import { useI18n } from "vue-i18n";

import { DsButton, DsCopyOutlineIcon, DsNotifier } from "@devsalsa/vue-core";

import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";

defineOptions({
  name: "AUWireInfo",
});

const { t } = useI18n();

const reference = AccountHandler.accountInfo.value?.wire_transfer_code || "";

async function copy(id: string): Promise<void> {
  const element = document.getElementById(id) as HTMLElement;
  await navigator.clipboard.writeText(element.innerText);
  DsNotifier.sendInfo({
    message: t("common.label.copied"),
    duration: 3,
  });
}
</script>
