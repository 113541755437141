import CAFlagCircleIcon from "@/shared/components/icon/marketplaces/CAFlagCircleIcon.vue";
import AUFlagCircleIcon from "@/shared/components/icon/marketplaces/AUFlagCircleIcon.vue";
import UKFlagCircleIcon from "@/shared/components/icon/marketplaces/UKFlagCircleIcon.vue";
import USFlagCircleIcon from "@/shared/components/icon/marketplaces/USFlagCircleIcon.vue";
import DEFlagCircleIcon from "@/shared/components/icon/marketplaces/DEFlagCircleIcon.vue";
import type { DsComponentRenderProp } from "@devsalsa/vue-core";
import { MarketplaceCode } from "@/shared/enums/MarketplaceCodeEnum";
import { computed } from "vue";
import type { MarketplacesCertifications } from "@/shared/services/AssetsService.types";
import AssetsService from "@/shared/services/AssetsService";
import type { IntlDateTimeFormats, IntlNumberFormats } from "vue-i18n";
import enUs from "@/locales/en-US.json";
import enCore from "@devsalsa/vue-core/dist/locales/en.json";
import enGb from "@/locales/en-GB.json";
import enCa from "@/locales/en-CA.json";
import enAu from "@/locales/en-AU.json";
import deDe from "@/locales/de-DE.json";
import CAWireInfo from "@/shared/helpers/marketplaces/wire/CAWireInfo.vue";
import UKWireInfo from "@/shared/helpers/marketplaces/wire/UKWireInfo.vue";
import AUWireInfo from "@/shared/helpers/marketplaces/wire/AUWireInfo.vue";
import EUWireInfo from "@/shared/helpers/marketplaces/wire/EUWireInfo.vue";
import USWireInfo from "@/shared/helpers/marketplaces/wire/USWireInfo.vue";

export function useMarketplace() {
  const datetimeFormats: IntlDateTimeFormats = {
    "en-US": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-GB": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-AU": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-CA": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "en-DE": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
    "de-DE": {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
      numeric: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      numericZeros: {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      },
      time: {
        hour: "numeric",
        minute: "numeric",
      },
      long: {
        year: "numeric",
        month: "long",
        day: "numeric",
        weekday: "long",
        hour: "numeric",
        minute: "numeric",
      },
      monthWithDay: {
        month: "long",
        day: "numeric",
      },
      monthDayYear: {
        month: "long",
        day: "numeric",
        year: "numeric",
      },
      monthYear: {
        month: "long",
        year: "numeric",
      },
    },
  };

  const numberFormats: IntlNumberFormats = {
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-GB": {
      currency: {
        style: "currency",
        currency: "GBP",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-AU": {
      currency: {
        style: "currency",
        currency: "AUD",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-CA": {
      currency: {
        style: "currency",
        currency: "CAD",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "en-DE": {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
    "de-DE": {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol",
      },
      decimal: {
        style: "decimal",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
      percent: {
        style: "percent",
        useGrouping: false,
      },
    },
  };

  const messages = {
    "en-US": { ...enUs, ...enCore },
    "en-GB": { ...enGb, ...enCore },
    "en-CA": { ...enCa, ...enCore },
    "en-AU": { ...enAu, ...enCore },
    "en-DE": { ...enUs, ...enCore },
    "de-DE": { ...deDe, ...enCore },
  };

  const currentMarketplace = () => {
    if (isMarketplace(MarketplaceCode.Ca)) {
      return MarketplaceCode.Ca;
    }
    if (isMarketplace(MarketplaceCode.Uk)) {
      return MarketplaceCode.Uk;
    }
    if (isMarketplace(MarketplaceCode.Au)) {
      return MarketplaceCode.Au;
    }
    if (isMarketplace(MarketplaceCode.De)) {
      return MarketplaceCode.De;
    }
    return MarketplaceCode.Us;
  };

  const getLocale = () => {
    if (isMarketplace(MarketplaceCode.Uk)) {
      return "en-GB";
    }
    if (isMarketplace(MarketplaceCode.Ca)) {
      return "en-CA";
    }
    if (isMarketplace(MarketplaceCode.Au)) {
      return "en-AU";
    }
    if (isMarketplace(MarketplaceCode.De)) {
      return "en-DE";
    }
    return "en-US";
  };

  const isMarketplace = (marketplace: MarketplaceCode) =>
    `${marketplace}.${import.meta.env.VITE_APP_DOMAIN}` ===
    window.location.host;

  const marketplaceIcon = (marketplace: string) => {
    if (marketplace === MarketplaceCode.Ca) {
      return CAFlagCircleIcon as DsComponentRenderProp;
    }
    if (marketplace === MarketplaceCode.Au) {
      return AUFlagCircleIcon as DsComponentRenderProp;
    }
    if (marketplace === MarketplaceCode.Uk) {
      return UKFlagCircleIcon as DsComponentRenderProp;
    }
    if (marketplace === MarketplaceCode.De) {
      return DEFlagCircleIcon as DsComponentRenderProp;
    }
    return USFlagCircleIcon as DsComponentRenderProp;
  };

  const currentMarketplaceWireInfo = () => {
    if (currentMarketplace() === MarketplaceCode.Ca) {
      return CAWireInfo as DsComponentRenderProp;
    }
    if (currentMarketplace() === MarketplaceCode.Uk) {
      return UKWireInfo as DsComponentRenderProp;
    }
    if (currentMarketplace() === MarketplaceCode.Au) {
      return AUWireInfo as DsComponentRenderProp;
    }
    if (currentMarketplace() === MarketplaceCode.De) {
      return EUWireInfo as DsComponentRenderProp;
    }
    return USWireInfo as DsComponentRenderProp;
  };

  const currentMarketplaceIcon = computed(() =>
    marketplaceIcon(currentMarketplace())
  );

  const currentMarketplaceName = computed(() =>
    currentMarketplace().toUpperCase()
  );

  const isTikTokShopMarketplace = computed(() => {
    return (
      isMarketplace(MarketplaceCode.Us) || isMarketplace(MarketplaceCode.Uk)
    );
  });

  const isTikTokAdCenterMarketplace = computed(() => {
    return isMarketplace(MarketplaceCode.Us);
  });

  const isReimbursementTaxesMarketplace = computed(() => {
    return (
      isMarketplace(MarketplaceCode.Us) || isMarketplace(MarketplaceCode.Ca)
    );
  });

  const isStripeMarketplace = computed(() => {
    return isMarketplace(MarketplaceCode.Us);
  });

  const marketplaceCertifications = async () => {
    const marketplaceCertifications: MarketplacesCertifications =
      await AssetsService.getCertifications();
    return marketplaceCertifications[
      currentMarketplace() as keyof MarketplacesCertifications
    ];
  };

  return {
    datetimeFormats,
    numberFormats,
    messages,
    isMarketplace,
    getLocale,
    marketplaceIcon,
    currentMarketplaceWireInfo,
    currentMarketplace,
    marketplaceCertifications,
    currentMarketplaceIcon,
    currentMarketplaceName,
    isTikTokShopMarketplace,
    isTikTokAdCenterMarketplace,
    isReimbursementTaxesMarketplace,
    isStripeMarketplace,
  };
}
